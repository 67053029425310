export const dbCollections = {
  ressources: 'Ressources',
  gallery: 'Gallery',
  visitsStats: 'Visits',
  downloadStats: 'Downloads',
  users: 'Users',
  categories: 'Categories',
}

export const dbStorageNamespaces = {
  ressources: 'ressources',
  gallery: 'gallery',
  categories: 'categories',
  users: 'users',
}